'use client'
import { useClientCookie } from '@/app/hooks/useCookie/useClientCookie'
import useFormatMessage from '@/app/hooks/useFormatMessage'
import useFinalRedirect from '@/app/hooks/useFinalRedirect'
import MitIDLogo from '@/components/MitIDLogo'
import T from '@/components/TranslationMessage'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { error } from '@/utils/log'
import { FormEvent, useState } from 'react'
import messages from './mitidform.messages'
import { MitIdRouteHandler } from '@/constants'

export default function MitIdForm() {
  const { setError } = useGlobalContext()
  const { sessionId } = useClientCookie()
  const finalRedirect = useFinalRedirect()
  const formatMessage = useFormatMessage()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    setLoading(true)
    try {
      e.preventDefault()

      if (!sessionId) {
        throw new Error('No active session')
      }

      const response = await fetch(
        MitIdRouteHandler + '?sessionId=' + sessionId,
        {
          method: 'GET',
        },
      )

      const responseBody = await response.json()

      if (!response.ok) {
        setError({
          title: formatMessage(messages.genericErrorTitle),
          content: formatMessage(messages.genericErrorMessage),
          errorCode: responseBody.errorCode,
        })
        setLoading(false)
      } else {
        finalRedirect(responseBody.location)
        setLoading(false)
      }
    } catch (e) {
      error(e)
      setLoading(false)
    }
  }

  return (
    <form
      className="flex w-full flex-col justify-start"
      onSubmit={handleSubmit}
    >
      <button
        type="submit"
        color="primary"
        className="justify-left mb-4 flex h-[46px] w-full items-center rounded-sm bg-[#0060e6] pb-1 pl-4 pr-3 pt-1 text-white"
        disabled={loading}
      >
        <MitIDLogo width="68px" height="38px" className="mr-8" />
        <T message={messages.mitIdLoginAction} />
      </button>
    </form>
  )
}
